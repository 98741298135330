<template>
  <div>
    <Loading :loading="loading" />

    <section class="section has-background-white-ter">
      <div class="container">
        <div class="box">
          <div class="columns is-vcentered" v-if="formRequest">
            <div class="column is-4">
              <span class="is-size-7 has-text-weight-bold">Form</span>
              <span class="is-size-1 montserrat">W-9</span>
            </div>
            <div class="column is-4 has-text-centered">
              <h3 class="title is-7 mb-0">Requested by</h3>
              <p>
                <span v-if="formRequest.sender.businessName">
                  {{ formRequest.sender.businessName }}<br />
                </span>
                <span v-else-if="formRequest.sender.name">
                  {{ formRequest.sender.name }}<br />
                </span>
                <a v-else :href="`mailto:${formRequest.sender.email}`">
                  {{ formRequest.sender.email }}
                </a>
              </p>
            </div>
            <div class="column is-4 has-text-right">
              <img
                v-if="formRequest.sender.logo"
                :src="images + formRequest.sender.logo"
                alt="requester-logo"
                style="max-width: 200px; max-height: 100px; margin: 5px"
              />
            </div>
          </div>
          <div class="columns is-vcentered" v-else>
            <div class="column is-6">
              <span class="is-size-7 has-text-weight-bold">Form</span>
              <span class="is-size-1 montserrat">W-9</span>
            </div>
            <div class="column is-6 has-text-right"></div>
          </div>
          <br />

          <div class="tabs is-small">
            <ul>
              <li :class="{ 'is-active': step == 1 }">
                <a>1. Name &amp; Business</a>
              </li>
              <li :class="{ 'is-active': step == 2 }">
                <a>2. Address &amp; Tax ID</a>
              </li>
              <li :class="{ 'is-active': step == 3 }">
                <a>3. Certification</a>
              </li>
              <li :class="{ 'is-active': step == 4 }">
                <a>4. Send Form</a>
              </li>
            </ul>
          </div>

          <div v-show="step == 10">
            <div class="notification is-warning p-6">
              This form request has already been fulfilled. If you would like to send a
              new form, you may start with a blank form
              <router-link :to="{ name: 'FormW9', query: null }">here</router-link>.
            </div>
          </div>

          <div v-show="step == 1">
            <div class="content">
              <div class="notification is-link is-light">
                <!-- <h6 class="title is-6 mb-1" v-if="formRequest">
                  This form was requested by:
                </h6> -->
                <!-- <p v-if="formRequest">
                  <span v-if="formRequest.sender.name"> {{ formRequest.sender.name }}<br /> </span>
                  <span v-if="formRequest.sender.businessName">
                    {{ formRequest.sender.businessName }}<br />
                  </span>
                  <a :href="`mailto:${formRequest.sender.email}`">
                    {{ formRequest.sender.email }}
                  </a>
                </p> -->

                <p>
                  <a @click="toggleShowInstructions()" style="text-decoration: none">
                    Is <b>W-9</b> the right form for you?
                    <span class="icon has-text-right">
                      <i v-if="!showInstructions" class="fas fa-angle-down"></i>
                      <i v-else class="fas fa-angle-up"></i>
                    </span>
                  </a>
                </p>

                <div v-if="showInstructions">
                  <p>
                    If you are not a U.S. citizen or resident alien, use
                    <router-link
                      :to="{ name: 'FormW8ben', query: { token: $route.query.token } }"
                    >
                      Form W-8BEN
                    </router-link>
                  </p>
                </div>
              </div>
            </div>

            <h4 class="title is-5">Name and Business</h4>
            <div class="columns is-multiline">
              <div class="column is-6">
                <Field
                  :retry="retry"
                  v-bind.sync="w9.line1Name"
                  :label="'1 Name as shown on your income tax return'"
                  :placeholder="'Name'"
                  :required="'Name is required.'"
                  :func="'name'"
                  :tooltip="`
                Enter the name of the individual, sole proprietor, partnership, or other entity.
              `"
                />
              </div>
              <div class="column is-6">
                <Field
                  :retry="retry"
                  v-bind.sync="w9.line2BusinessName"
                  :label="'2 Business name/disregarded entity name, if different from name'"
                  :placeholder="'Business name'"
                  :required="false"
                  :tooltip="`
                If you have a business name, trade name, DBA name, or disregarded
                entity name, you may enter it on line 2.
              `"
                />
              </div>
              <div class="column is-6">
                <Select
                  :retry="retry"
                  v-bind.sync="w9.line3Classification"
                  :options="[
                    {
                      value: 'individual',
                      name: 'Individual/sole proprietor/single-member LLC',
                    },
                    { value: 'ccorp', name: 'C Corporation' },
                    { value: 'scorp', name: 'S Corporation' },
                    { value: 'partnership', name: 'Partnership' },
                    { value: 'trustestate', name: 'Trust/estate' },
                    { value: 'llc', name: 'Limited liability company' },
                    { value: 'other', name: 'Other' },
                  ]"
                  :label="'3 Federal tax classification'"
                  :placeholder="'Select one'"
                  :required="'You must select a classification.'"
                  :tooltip="`
                Select the appropriate classification for the person whose name is 
                entered on line 1.
              `"
                />
              </div>

              <div class="column is-6" v-if="w9.line3Classification.value == 'llc'">
                <Select
                  :retry="retry"
                  v-bind.sync="w9.line3TaxClassification"
                  :options="[
                    { value: 'c', name: 'C (C Corporation)' },
                    { value: 's', name: 'S (S Corporation)' },
                    { value: 'p', name: 'P (Partnership)' },
                  ]"
                  :label="'LLC Tax Classification'"
                  :placeholder="'Select one'"
                  :required="'Please select an LLC tax classification'"
                />
              </div>

              <div
                class="column is-6"
                v-else-if="w9.line3Classification.value == 'other'"
              >
                <Field
                  :retry="retry"
                  v-bind.sync="w9.line3Other"
                  :label="'Other entity'"
                  :required="'Please specify other entity'"
                />
              </div>

              <div class="column is-6" v-else></div>

              <div class="column is-12">
                <label class="checkbox">
                  <input type="checkbox" v-model="hasExemptions" />
                  I have payee or FACTA exemptions (only applies to certain entities).
                </label>
              </div>

              <div class="column is-6" v-show="hasExemptions">
                <Field
                  :retry="retry"
                  v-bind.sync="w9.line4PayeeExemption"
                  :label="'Exempt payee code'"
                  :placeholder="'Exempt payee code'"
                  :required="false"
                  :func="'payeeCode'"
                  :tooltip="`
                    Payee exempt codes are used to identify different types 
                    of payees who are exempt from backup withholding. Valid payee codes are 
                    numbers 1 through 13.
                  `"
                />
              </div>

              <div class="column is-6" v-show="hasExemptions">
                <Field
                  :retry="retry"
                  v-bind.sync="w9.line4FactaExemption"
                  :label="'Exemption from FATCA reporting code'"
                  :placeholder="'Exempt FACTA code'"
                  :required="false"
                  :func="'factaCode'"
                  :tooltip="`
                    Exempt FACTA codes identify payees that are exempt from reporting under FATCA.
                    Valid FACTA codes are letters A through M.
                  `"
                />
              </div>
            </div>

            <div class="buttons">
              <button class="button is-link" @click="nextStep()" :disabled="!step1Valid">
                Continue
              </button>
            </div>
          </div>

          <div v-show="step == 2">
            <h4 class="title is-5">Address</h4>

            <div class="columns is-multiline">
              <div class="column is-12">
                <Field
                  :retry="retry"
                  v-bind.sync="w9.line5Address"
                  :label="'5 Address (number, street, and apt. or suite no.)'"
                  :placeholder="'Address'"
                  :func="'address'"
                  :required="'You must enter an address.'"
                  :tooltip="`
                This is where the requester of this Form W-9 will mail your information returns.
              `"
                />
              </div>
              <div class="column is-4">
                <Field
                  :retry="retry"
                  :label="'City or town'"
                  v-bind.sync="w9.line6City"
                  :required="'You must enter a city or town.'"
                />
              </div>
              <div class="column is-4">
                <Select
                  :retry="retry"
                  v-bind.sync="w9.line6State"
                  :options="selectState"
                  :label="'State'"
                  :placeholder="'Select a State'"
                />
              </div>
              <div class="column is-4">
                <Field
                  :label="'Zip code'"
                  v-bind.sync="w9.line6Zip"
                  :func="'zip'"
                  :retry="retry"
                />
              </div>

              <div class="column is-12">
                <Field
                  :retry="retry"
                  v-bind.sync="w9.line7Account"
                  :label="'7 List account number(s) here (optional)'"
                  :placeholder="'Account number(s)'"
                  :required="false"
                />
              </div>
            </div>

            <br />
            <h4 class="title is-5">Tax ID Number</h4>

            <div class="columns is-multiline">
              <div class="column is-6">
                <!-- TODO make this a combo input -->
                <!-- TODO this is a little hacked to make empty value show ssn -->
                <Select
                  :retry="retry"
                  v-bind.sync="tinType"
                  :options="[
                    { value: 'ein', name: 'Employer identification number (EIN)' },
                  ]"
                  :placeholder="'Social security number (SSN)'"
                  :label="'Type of taxpayer identification number'"
                  :required="false"
                />
              </div>

              <div class="column is-6" v-if="!tinType.value">
                <!-- TODO add a tooltip here -->

                <Field
                  :retry="retry"
                  :label="'Social security number (SSN)'"
                  v-bind.sync="w9.ssn"
                  :func="'ssn'"
                  :path="'/validate'"
                  :meta="{ user, iso: 'us', type: 'individual' }"
                  :required="'You must enter a social security number.'"
                />
              </div>

              <div class="column is-6" v-else>
                <!-- Per instructions, this should be associated with country on line 3 -->
                <Field
                  :retry="retry"
                  :label="'Employer identification number (EIN)'"
                  v-bind.sync="w9.ein"
                  :func="'ein'"
                  :path="'/validate'"
                  :meta="{ user, iso: 'us', type: 'entity' }"
                  :required="'You must enter an employer identification number.'"
                />
              </div>
            </div>
            <div class="buttons">
              <button class="button is-link" @click="nextStep()" :disabled="!step2Valid">
                Continue
              </button>

              <button class="button is-link is-outlined" @click="previousStep()">
                Go Back
              </button>
            </div>
          </div>

          <div v-show="step == 3">
            <h5 class="title is-5">Certification</h5>
            <div class="content">
              <p>Under penalties of perjury, I certify that:</p>
              <ol>
                <li>
                  The number shown on this form is my correct taxpayer identification
                  number (or I am waiting for a number to be issued to me); and
                </li>
                <li>
                  I am not subject to backup withholding because: (a) I am exempt from
                  backup withholding, or (b) I have not been notified by the Internal
                  Revenue Service (IRS) that I am subject to backup withholding as a
                  result of a failure to report all interest or dividends, or (c) the IRS
                  has notified me that I am no longer subject to backup withholding; and
                </li>
                <li>I am a U.S. citizen or other U.S. person (defined below); and</li>
                <li>
                  The FATCA code(s) entered on this form (if any) indicating that I am
                  exempt from FATCA reporting is correct.
                </li>
              </ol>
              <p>
                <b>Certification instructions.</b> You must cross out item 2 above if you
                have been notified by the IRS that you are currently subject to backup
                withholding because you have failed to report all interest and dividends
                on your tax return. For real estate transactions, item 2 does not apply.
                For mortgage interest paid, acquisition or abandonment of secured
                property, cancellation of debt, contributions to an individual retirement
                arrangement (IRA), and generally, payments other than interest and
                dividends, you are not required to sign the certification, but you must
                provide your correct TIN. See the instructions for Part II, later.
              </p>
            </div>

            <div class="columns is-multiline">
              <div class="column is-12">
                <!-- TODO make a checkbox agreement about electronic signature -->
                <Field
                  :retry="retry"
                  :label="'Signature of U.S. Person'"
                  :placeholder="'Signature'"
                  v-bind.sync="w9.signature"
                  :signature="true"
                  :func="'name'"
                  :required="'Signature is required.'"
                />
              </div>
              <div class="column is-6">
                <Field
                  :retry="retry"
                  :label="`Today's Date`"
                  :type="'date'"
                  :func="'signatureDate'"
                  v-bind.sync="w9.signatureDate"
                />
              </div>
            </div>

            <div class="buttons">
              <button class="button is-link" @click="nextStep()" :disabled="!step3Valid">
                Continue
              </button>

              <button class="button is-link is-outlined" @click="previousStep()">
                Go Back
              </button>
            </div>
          </div>

          <div v-show="step == 4">
            <div class="columns is-multiline">
              <div class="column is-12">
                <h5 class="title is-5">Send Your Form</h5>

                <div class="notification is-link is-light" v-if="guestUser">
                  You are sending this form as a <strong>guest</strong>. We will send a
                  one-time pin to your email address, and you will need to enter it before
                  your form is delivered. If you would like to send this form as a
                  registered user, please <a @click="modal = 'login'">log in</a> or
                  <a @click="modal = 'signup'">sign up</a>.
                </div>

                <div class="field">
                  <Field
                    :retry="retry"
                    :label="'Your email address'"
                    :placeholder="'Your email address'"
                    v-bind.sync="form.senderEmail"
                    :func="'email'"
                  />
                </div>
              </div>
              <div class="column is-12" v-if="formRequest">
                <h3 class="title is-7 mb-1">
                  This W-9 will be electronically delivered to
                </h3>
                <span v-if="formRequest.sender.businessName">
                  {{ formRequest.sender.businessName }}<br />
                </span>
                <span v-else-if="formRequest.sender.name">
                  {{ formRequest.sender.name }}<br />
                </span>
                <a v-else :href="`mailto:${formRequest.sender.email}`">
                  {{ formRequest.sender.email }}
                </a>
                <br />
              </div>
              <div class="column is-12" v-else>
                <Field
                  :retry="retry"
                  :label="'Recipient\'s email address'"
                  :placeholder="'Recipient\'s email address'"
                  v-bind.sync="form.receiverEmail"
                  :func="'email'"
                />
              </div>
            </div>
            <div class="notification is-danger" v-if="error">
              {{ error }}
            </div>

            <div class="buttons">
              <button
                v-if="guestUser"
                class="button is-link"
                @click="sendPin()"
                :class="{ 'is-loading': processing }"
                :disabled="!allValid"
              >
                Continue
              </button>

              <button
                v-else
                class="button is-link"
                @click="submit()"
                :class="{ 'is-loading': processing }"
                :disabled="!allValid"
              >
                Send Form
              </button>

              <button class="button is-link is-outlined" @click="previousStep()">
                Go Back
              </button>
            </div>
          </div>
        </div>
        <div
          class="has-text-centered has-text-grey-light whitelabel-footer"
          v-if="whitelabel"
        >
          Securely processed by
          <a href="https://nextform.app/" target="_blank">Nextform</a> on behalf of
          {{
            formRequest.sender.businessName ||
            formRequest.sender.name ||
            formRequest.sender.email
          }}
        </div>
      </div>
    </section>
    <div class="container has-text-grey-light has-text-centered" v-if="whitelabel"></div>

    <div class="modal is-active" v-if="modal == 'pin'">
      <!-- <div class="modal is-active"> -->
      <div class="modal-background" @click="modal = ''"></div>
      <div class="modal-content" style="max-width: 500px">
        <div class="box">
          <h2 class="title is-4 has-text-centered">One-time PIN</h2>
          <h3 class="subtitle is-6 has-text-centered"></h3>
          <br />

          <p>
            Enter the PIN sent to
            <strong>{{ form.senderEmail.value }}</strong>
          </p>

          <br />

          <Field
            :label="'Six-digit PIN'"
            :placeholder="'000000'"
            v-bind.sync="pin"
            :large="true"
            :green="true"
            :meta="{ email: form.senderEmail.value }"
            :func="'pin'"
            @submit="submit()"
            :path="'/oneTimePin/pin'"
          />

          <div class="buttons">
            <button
              class="button is-link"
              :class="{ 'is-loading': processing }"
              @click="submit()"
              :disabled="!pin.valid"
            >
              Send Form
            </button>
          </div>
          Want to
          <a @click="modal = 'login'">log in</a> or
          <a @click="modal = 'signup'">sign up</a> instead?
        </div>
      </div>
    </div>

    <div class="modal is-active" v-if="modal == 'signup'">
      <div class="modal-background" @click="modal = ''"></div>
      <div class="modal-content" style="max-width: 500px">
        <div class="box">
          <h2 class="title is-4 has-text-centered">Create an Account</h2>
          <h3 class="subtitle is-6 has-text-centered">
            Please create a free account to continue
          </h3>
          <SignupForm @success="setUserAndContinue" />
          <br />
          Want to <a @click="modal = ''">continue as guest</a> or
          <a @click="modal = 'login'">log in</a>?
        </div>
      </div>
    </div>

    <div class="modal is-active" v-if="modal == 'login'">
      <div class="modal-background" @click="modal = ''"></div>
      <div class="modal-content" style="max-width: 500px">
        <div class="box">
          <h2 class="title is-4 has-text-centered">Log In</h2>
          <h3 class="subtitle is-6 has-text-centered">
            Please log in to your account to continue
          </h3>
          <LoginForm @success="setUserAndContinue" />
          <br />
          Want to <a @click="modal = ''">continue as guest</a> or
          <a @click="modal = 'signup'">sign up</a>?
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.whitelabel-footer {
  font-size: 0.75rem;
}
.whitelabel-footer a,
.whitelabel-footer a:visited {
  color: #888;
}
.whitelabel-footer a:hover,
.whitelabel-footer a:active {
  color: #222;
}
/* non-interactive tabs */
.tabs ul li a {
  cursor: default;
}
.tabs ul li a:hover {
  cursor: default;
  border-bottom-color: #dbdbdb;
}
.tabs ul li.is-active a {
  cursor: default;
  border-bottom-color: #3273dc;
}
</style>

<script>
import {
  origin,
  api,
  field,
  select,
  parseError,
  images,
  flattenFields,
} from '@/modules/util'
import selectState from '@/modules/selectState.json'
import dayjs from 'dayjs'

export default {
  name: 'FormW9',
  props: ['user'],
  data() {
    return {
      retry: 0,
      origin,
      images,
      loading: true,
      processing: false,
      modal: '',
      error: '',
      pin: field(),
      step: 1,
      tinType: select(),
      hasExemptions: false,
      selectState,
      formRequest: null,
      showInstructions: false,

      token: this.$route.query.token || '',

      form: {
        senderEmail: field(),
        receiverEmail: field(),
      },

      w9: {
        line1Name: field(),
        line2BusinessName: field(),
        line3Classification: select(),
        line3TaxClassification: select(),
        line3Other: field(),
        line4PayeeExemption: field({ valid: true }),
        line4FactaExemption: field({ valid: true }),
        line5Address: field(),
        line6City: field(),
        line6State: select(),
        line6Zip: field(),
        line7Account: field(),
        ssn: field(),
        ein: field(),
        signature: field(),
        signatureDate: field({ value: dayjs().format('YYYY-MM-DD') }),
      },
    }
  },

  watch: {
    '$route.query.step': {
      immediate: true,
      handler() {
        try {
          this.step = parseInt(this.$route.query.step) || 1
        } catch (err) {
          this.step = 1
        }
      },
    },
    // TODO possibly get rid of this
    user: {
      immediate: true,
      handler() {
        if (this.user && this.user.name && !this.w9.line1Name.value) {
          this.w9.line1Name.value = this.user.name
          this.w9.line1Name.valid = true
        }
        if (this.user && this.user.email && !this.form.senderEmail.value) {
          this.form.senderEmail.value = this.user.email
          this.form.senderEmail.valid = true
        }
      },
    },
    formRequest: {
      immediate: true,
      handler() {
        if (this.formRequest) {
          if (this.formRequest.status == 'complete' && this.formRequest.type != 'link') {
            this.nextStep(10)
          }
          this.form.receiverEmail.value = this.formRequest.sender.email
          this.form.receiverEmail.valid = true
          this.form.senderEmail.value = this.formRequest.receiverEmail
          this.form.senderEmail.valid = Boolean(this.formRequest.receiverEmail)

          // TODO expand the autofill
          if (this.formRequest.autofill) {
            this.w9.line1Name.value = this.formRequest.autofill.name || ''
          }

          // handle autofill
          if (this.formRequest.autofill && this.formRequest.formNumber == 'w9') {
            // TODO add some code to translate the autofill from w9
            for (const key in this.formRequest.autofill) {
              const value = this.formRequest.autofill[key]
              if (typeof value == 'string') {
                this.w9[key].value = value
              } else if (typeof value == 'object') {
                this.w9[key].value = value.value
                this.w9[key].disabled = value.disabled
              }
            }
          }

          this.retry++
        }
      },
    },
    'w9.line3Classification.value': {
      immediate: true,
      handler() {
        if (this.w9.line3Classification.value == 'llc') {
          this.w9.line3TaxClassification = select({ value: '', valid: false })
          this.w9.line3Other = field({ value: '', valid: true })
        } else if (this.w9.line3Classification.value == 'other') {
          this.w9.line3TaxClassification = select({ value: '', valid: true })
          this.w9.line3Other = field({ value: '', valid: false })
        } else {
          this.w9.line3TaxClassification = select({ value: '', valid: true })
          this.w9.line3Other = field({ value: '', valid: true })
        }
      },
    },
    'tinType.value': {
      immediate: true,
      handler() {
        if (this.tinType.value == '') {
          this.w9.ssn = field({ value: '', valid: false })
          this.w9.ein = field({ value: '', valid: true })
        } else {
          this.w9.ssn = field({ value: '', valid: true })
          this.w9.ein = field({ value: '', valid: false })
        }
      },
    },
    hasExemptions: {
      immediate: true,
      handler() {
        if (!this.hasExemptions) {
          this.w9.line4PayeeExemption = field({ value: '', valid: true })
          this.w9.line4FactaExemption = field({ value: '', valid: true })
        } else {
          this.w9.line4PayeeExemption = field({ value: '', valid: true })
          this.w9.line4FactaExemption = field({ value: '', valid: true })
        }
      },
    },
  },

  computed: {
    guestUser() {
      return !this.formRequest && !this.user.id
    },

    whitelabel() {
      return this.formRequest && this.$route.query.token
    },

    step1Valid() {
      return (
        this.w9.line1Name.valid &&
        this.w9.line2BusinessName.valid &&
        this.w9.line3Classification.valid &&
        this.w9.line3TaxClassification.valid &&
        this.w9.line3Other.valid &&
        this.w9.line4PayeeExemption.valid &&
        this.w9.line4FactaExemption.valid
      )
    },

    step2Valid() {
      return (
        this.w9.line5Address.valid &&
        this.w9.line6City.valid &&
        this.w9.line6State.valid &&
        this.w9.line6Zip.valid &&
        this.w9.line7Account.valid &&
        this.w9.ssn.valid &&
        this.w9.ein.valid
      )
    },

    step3Valid() {
      return this.w9.signature.valid && this.w9.signatureDate.valid
    },

    allValid() {
      return (
        this.step1Valid &&
        this.step2Valid &&
        this.step3Valid &&
        this.form.senderEmail.valid &&
        this.form.receiverEmail.valid
      )
    },
  },

  methods: {
    async init() {
      try {
        this.loading = true
        const token = this.token
        if (!token) throw 'No token.'
        const res = await api.get(`/formRequests/autofill?token=${this.token}`)
        if (!res.data) throw 'No form request.'
        this.formRequest = res.data
        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },

    toggleShowInstructions() {
      this.showInstructions = !this.showInstructions
    },

    nextStep(i) {
      // build the query
      const query = { step: i || this.step + 1 }
      if (this.token) query.token = this.token
      this.$router.push({ query })
    },

    previousStep(i) {
      const query = { step: i || this.step - 1 }
      if (this.token) query.token = this.token
      this.$router.push({ query })
    },

    // async checkUserBeforeSend() {
    //   if (this.user.id || this.formRequest) {
    //     this.modal = ''
    //     this.nextStep(4)
    //   } else this.modal = 'guest'
    // },

    async setUserAndContinue() {
      this.loading = true
      this.$emit('init')
      this.modal = ''
      this.nextStep(4)
      this.loading = false
    },

    async sendPin() {
      try {
        this.processing = true
        await api.post('/oneTimePins', { email: this.form.senderEmail.value })
        this.processing = false
        this.modal = 'pin'
      } catch (err) {
        this.error = parseError(err)
        this.processing = false
      }
    },

    async submit() {
      try {
        this.processing = true

        // flatten fields and add common fields to the form object
        const form = flattenFields(this.form)
        const w9 = flattenFields(this.w9)
        form.name = w9.line1Name
        form.businessName = w9.line2BusinessName

        // post the form
        const res = await api.post('/forms/w9', {
          form,
          w9,
          formRequest: this.formRequest,
          pin: this.pin.value,
        })

        // go to the thank-you URL
        if (this.formRequest && this.formRequest.thankYouUrl) {
          window.top.location.href = this.formRequest.thankYouUrl
        } else {
          this.$router.push({ name: 'Preview', params: { token: res.data.token } })
        }
      } catch (err) {
        this.error = parseError(err)
        this.processing = false
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
